import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageTitleBar } from '../common/PageTitleBar';
import { Tabs } from '../common/Tabs';
import { fetchSymbolIfNeeded } from '../../actions/symbols';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Exchange from '../common/Exchange';
import { useIntl } from 'react-intl';
import {
  getNewMarginBuyUrl,
  getMarginRefundUrlSymbolDetails,
  getSmallLotOrderUrl,
  getSymbolBuySellUrl,
  getOddLotOrderUrl
} from '../../utils/formatter';
import {
  isUseq,
  getExchangeResourceId,
  getSymbolRoot,
  getInternationalSymbolRoot,
  isJpeq
} from '../../utils/symbol';
import _ from 'lodash';
import TradeSpeedDial from './TradeSpeedDial';
import { Link } from 'react-router-dom';
import navigationTypes from '../../actions/_navigationTypes';
import PageSubtitle from './PageSubtitle';
import SymbolSummary from './SymbolSummary';
import SymbolIndicators from './SymbolIndicators';
import SymbolHistorical from './SymbolHistorical';
import { SymbolSettings } from '../symbolOverview/SymbolSettings';
import { isMonexApp } from '../../utils/common';
import { DesktopHelpBar } from '../common/DesktopHelpbar';
import SymbolDetailHeader from '../common/SymbolDetailHeader';
import TradeButtons from '../marketBoard/TradeButtons';

import RefineIcon from '../../icons/Icon-Refine.svg';
import EditIcon from '../../icons/Icon-Settings.svg';
import { setDocumentTitle } from '../../utils/html';
import MobileSymbolDetailHeader from './MobileSymbolDetailHeader';
import useActions from '../../utils/hooks/useActions';
import useLocalize from '../../utils/hooks/useLocalize';
import { useDesktopWidth } from '../../utils/hooks/useDesktopWidth';
import useSymbolMetadata from '../../utils/hooks/useSymbolMetadata';
import { fetchUserMemosIfNeeded } from '../../actions/memos';
import { userSelector } from '../../selectors/userSelectors';

const tabValues = ['summary', 'indicators', 'historical'];

const SymbolOverview = ({ match, history }) => {
  const [open, setOpen] = useState(false);
  const [tabsToDisplay, setTabsToDisplay] = useState(tabValues);
  const [selectedTab, setSelectedTab] = useState(0);
  const fetchUserMemos = useActions(fetchUserMemosIfNeeded, []);

  const [getSymbol] = useActions([fetchSymbolIfNeeded], []);

  const intl = useIntl();
  const localize = useLocalize();
  const isDesktopWidth = useDesktopWidth();

  const user = useSelector(userSelector);

  // Symbol is considered to be loading until a symbol record is present.
  const symbol = match.params.symbol;
  const symbolMetadata = useSymbolMetadata(symbol);
  const isJpeqSymbol = isJpeq(symbolMetadata.assetClass);
  const isUseqSymbol = isUseq(symbolMetadata.assetClass);
  const showTradeSpeedDial = isJpeqSymbol || isUseqSymbol;

  useEffect(() => {
    const paramSymbol = symbol;

    getSymbol(paramSymbol);

    const currentTabs = tabValues.filter((val) =>
      !isJpeqSymbol ? val !== 'indicators' : true
    );

    setTabsToDisplay(currentTabs);
    setSelectedTab(currentTabs[0]);
    fetchUserMemos([paramSymbol]);
  }, [symbol, isJpeqSymbol, fetchUserMemos, getSymbol]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleTabChange = useCallback(
    (event, value) => {
      if (!value) {
        return;
      }

      let index = _.findIndex(tabsToDisplay, (tab) => {
        return tab === value;
      });

      setSelectedTab(index);
    },
    [tabsToDisplay, setSelectedTab]
  );

  const setTitle = useCallback(
    (metadata) => {
      if (!metadata) {
        setDocumentTitle(localize('document.title.common'));
        return;
      }

      const exchangeName = localize(getExchangeResourceId(metadata));
      const symbolCode = isJpeq(metadata.assetClass)
        ? getSymbolRoot(metadata.id)
        : getInternationalSymbolRoot(metadata.id);
      const title = intl.formatMessage(
        {
          id: 'document.title.symbol.details'
        },
        {
          symbolCode: symbolCode,
          exchange: exchangeName,
          description: metadata.name
        }
      );
      setDocumentTitle(title);
    },
    [intl, localize]
  );

  setTitle(symbolMetadata);

  const leftButton = !isMonexApp() && (
    <IconButton
      onClick={() => {
        // For some reason, history.length does not seem to start at 0,
        // it consistently starts at 2.  That's why the 'if' statement below
        // looks slightly odd, but it is correct.
        if (history.length <= 2) {
          return history.push('/');
        }

        return history.goBack();
      }}
      size="large"
    >
      <ArrowBackIcon />
    </IconButton>
  );

  const rightButton = (
    <IconButton onClick={handleClickOpen} size="large">
      <Link to="#">
        <img src={RefineIcon} className={'refine-icon'} alt="" />
      </Link>
    </IconButton>
  );

  const rightSettingsDesktopButton = (
    <IconButton onClick={handleClickOpen} size="large">
      <Link to="#">
        <img src={EditIcon} className={'refine-icon'} alt="" />
      </Link>
    </IconButton>
  );

  const mdash = '\u2014';

  const pageTitleBar = !isJpeqSymbol ? (
    <div>{_.get(symbolMetadata, 'jpShortName', mdash)}</div>
  ) : (
    <div>
      {_.get(symbolMetadata, 'root', '')}
      {_.get(symbolMetadata, 'exchangeName') &&
      _.get(symbolMetadata, 'jpSection') ? (
        <Exchange
          name={symbolMetadata.exchangeName}
          section={symbolMetadata.jpSection}
        />
      ) : (
        mdash
      )}
      &nbsp;
      {_.get(symbolMetadata, 'jpShortName', mdash)}
    </div>
  );

  const pageTabs = (
    <div className="symbol-overview-title">
      {isDesktopWidth && (
        <div className={'symbol-overview-pro-header-edit-button'}>
          {rightSettingsDesktopButton}
        </div>
      )}

      <Tabs
        tabValues={tabsToDisplay}
        selectedTabIndex={selectedTab}
        handleTabChange={handleTabChange}
        labelKeyPrefix={'symbol.details.tab.'}
      />
    </div>
  );

  const symbolSummary = <SymbolSummary symbol={symbol} />;
  const symbolIndicators = <SymbolIndicators symbol={symbol} />;
  const symbolHistorical = <SymbolHistorical symbol={symbol} />;

  const symbolSettings = (
    <SymbolSettings
      handleClose={handleClose}
      open={open}
      pageTitleBar={pageTitleBar}
    />
  );

  let actions = [
    {
      name: localize('symbol.details.button.cash.buy'),
      className: 'trade-speed-dial-cash-buy',
      targetUrl: getSymbolBuySellUrl(
        symbolMetadata.assetClass,
        symbol,
        user,
        'buy',
        isDesktopWidth
      ),
      navigationType: navigationTypes.SD_CASH_BUY_CLICK,
      postUserTrade: true
    },
    {
      name: localize('symbol.details.button.cash.sell'),
      className: 'trade-speed-dial-cash-sell',
      targetUrl: getSymbolBuySellUrl(
        symbolMetadata.assetClass,
        symbol,
        user,
        'sell',
        isDesktopWidth
      ),
      navigationType: navigationTypes.SD_CASH_SELL_CLICK,
      postUserTrade: true
    }
  ];

  if (isJpeqSymbol && user.isMarginEnabled) {
    actions.push({
      name: localize('symbol.details.button.margin.buy'),
      className: 'trade-speed-dial-margin-buy',
      targetUrl: getNewMarginBuyUrl(user.dm, user.attrSrcKey, symbol),
      navigationType: navigationTypes.SD_MARGIN_ORDER_CLICK,
      postUserTrade: true
    });
    actions.push({
      name: localize('symbol.details.button.margin.refund'),
      className: 'trade-speed-dial-margin-refund',
      targetUrl: getMarginRefundUrlSymbolDetails(
        user.dm,
        user.attrSrcKey,
        symbol
      ),
      navigationType: navigationTypes.SD_MARGIN_SELL_CLICK,
      postUserTrade: true
    });
  }

  if (isJpeqSymbol) {
    actions.push({
      name: localize('symbol.details.button.small.lot.order'),
      className: 'trade-speed-dial-cash-buy',
      targetUrl: getSmallLotOrderUrl(user.dm, user.attrSrcKey, symbol),
      navigationType: navigationTypes.SD_SMALL_LOT_ORDER_CLICK,
      postUserTrade: true
    });

    actions.push({
      name: localize('symbol.details.button.odd.lot.order'),
      className: 'trade-speed-dial-cash-buy',
      targetUrl: getOddLotOrderUrl(user, symbol),
      navigationType: navigationTypes.SD_ODD_LOT_ORDER_CLICK,
      postUserTrade: true
    });
  }

  const tradeSpeedDial = (
    <TradeSpeedDial
      label={localize('symbol.details.trade.dial.label')}
      actions={actions}
      show={showTradeSpeedDial}
      symbolId={symbol}
      encryptedId={user.encryptedId}
    />
  );

  const symbolDetailsDesktopHeader = (
    <div>
      <DesktopHelpBar route={'symbol'} symbolId={symbol} />
      <div className={'symbol-overview-pro-header'}>
        <div>
          <div id={'ticker'} className={'symbol-overview-ticker'}>
            <SymbolDetailHeader symbol={symbol} displayTradeStatus={true} displayExRights={true} />
            <div id={'tradeBar'} className={'symbol-overview-trade-bar'}>
              <TradeButtons symbol={symbol} isUseq={isUseqSymbol} />
            </div>
            <div className={'symbol-overview-page-subtitle'}>
              <PageSubtitle
                show={isJpeqSymbol}
                symbolData={symbolMetadata}
                showExchange={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const symbolDetailsHeaderMobile = (
    <>
      <PageTitleBar
        title={pageTitleBar}
        leftButton={leftButton}
        rightButton={rightButton}
        isNoHelpBar={true}
      />
      <div>
        <PageSubtitle show={isJpeqSymbol} symbolData={symbolMetadata} />
        <MobileSymbolDetailHeader symbol={symbol} />
      </div>
    </>
  );

  return (
    <div>
      {isDesktopWidth ? symbolDetailsDesktopHeader : symbolDetailsHeaderMobile}
      {pageTabs}
      {tabsToDisplay[selectedTab] === 'summary' && symbolSummary}
      {tabsToDisplay[selectedTab] === 'indicators' && symbolIndicators}
      {tabsToDisplay[selectedTab] === 'historical' && symbolHistorical}
      {!isDesktopWidth && tradeSpeedDial}
      {symbolSettings}
    </div>
  );
};

export default SymbolOverview;
